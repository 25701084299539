import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],

    state: {
        auctionValue: [],
        status: '',
        token: '',
        user: {},
        weights: {},
        weightsDirty: false,
        auctionFilters: [],
        latestPage: [],
        searchFilters: {
            licensePlate: '',
            fuel: [],
            transmission: [],
            bodyStyle: [],
            score: 0,
            itr: 0,
            etr: 0,
            build: 2000,
            mileage: [0, 300000],
            zo: 0,
            recentlyMaintained: false,
            publishedDate: "",
            includeMakeModels: [],
            excludeMakeModels: [],
            includeEtrScore: true,
            includeModelScore: true,
            includeMotorScore: true,
            includeMaintenanceScore: true,
            includeSuppliers: []
        },
        savedSearchFilters: []
    },

    mutations: {
        weightsDirty(state, newValue) {
            state.weightsDirty = newValue
        },
        auctionValue(state, newValue) {
            state.auctionValue = newValue
        },
        auctionFilters(state, newValue) {
            state.auctionFilters = newValue
        },
        weights(state, newValue) {
            state.weights = newValue
        },
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, resp) {
            state.status = 'success'
            state.user = resp.user
            state.token = resp.token
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = ''
        },
        saveDefaultWeight(state, newValue) {
            state.user.default_weight = newValue
        },
        setLatestPage(state, value) {
            let foundIndex = state.latestPage.findIndex(x => x.id === value.id)
            if (foundIndex !== -1){
                state.latestPage[foundIndex] = value
            } else {
                state.latestPage.push(value)
            }
        },
        searchfilters(state, newValue) {
            Object.keys(newValue).forEach((item) => {
                state.searchFilters[item] = newValue[item]
            })
        },
        savedSearchFilters(state, newValue) {
            state.savedSearchFilters = newValue
        },
    },
    actions: {
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({url: '/api/auth/login', data: user, method: 'POST'})
                    .then(resp => {
                        const token = resp.data.token
                        localStorage.setItem('token', `Bearer ${token}`)
                        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                        commit('auth_success', resp.data)
                    }).then(resp => {
                    resolve(resp)
                })
                    .catch(err => {
                        commit('auth_error')
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },
        getAuctionFilters({commit}) {
            return new Promise((resolve, reject) => {
                axios({url: `/api/data/auctionfilters`, method: 'GET'})
                    .then(resp => {
                        commit('auctionFilters', resp.data.data)
                    }).then(resp => {
                    resolve(resp)
                })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getWeights({state, commit}, id) {
            return new Promise((resolve, reject) => {
                axios({url: `/api/admin/weight/${id}`, method: 'GET'})
                    .then(resp => {
                        let changed = state.user.default_weight != id
                        commit('weights', resp.data)
                        commit('weightsDirty', false)
                        commit('saveDefaultWeight', id)
                        if (changed) {
                            return new Promise((resolve, reject) => {
                                axios({url: '/api/user/default-weight', data: {id: id}, method: 'PUT'})
                                    .then(resp => {
                                        console.log(resp)
                                        resolve(resp)
                                    })
                                    .catch(err => {
                                        reject(err)
                                    })
                            })
                        }
                    }).then(resp => {
                    resolve(resp)
                })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        saveWeights({state, commit}, id) {
            return new Promise((resolve, reject) => {
                axios({url: `/api/admin/weight/${id}`, data: state.weights.weights, method: 'PUT'})
                    .then(resp => {
                        commit('weightsDirty', false)
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        logout({commit}) {
            return new Promise((resolve) => {
                commit('logout')
                localStorage.removeItem('token')
                delete axios.defaults.headers.common['Authorization']
                resolve()
            })
        },
        setLatestPage({commit}, page) {
            commit('setLatestPage', page)
        },
        async getSavedSearchFilters({commit}) {
            let {data} = await  axios({url: `/api/user/saved-search-filters`, method: 'GET'})
            commit('savedSearchFilters', data)
            return data
        },
    },
    modules: {},
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
    }

})
